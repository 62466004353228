import { useEffect, useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { ApolloProvider, useLazyQuery } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GET_USER_BY_ID } from './graphql/queries';
import { ViewportProvider } from './hooks/useViewport';
import useMaintenanceMode from './hooks/useMaintenanceMode';
import { GlobalStyles, GlobalThemeProvider } from './styles/Global';
import {
  onAuthChanged,
  signInAuthUser,
  signInWithToken,
} from './firebase/auth';
import { RouterProvider, Routes } from './routes';
import { client } from './graphql';
import { isAuthenticatedCache, authUserCache } from './graphql/cache';
import { client as httpClient } from './http';
import ErrorBoundary from './ErrorBoundary';
import MaintenanceMode from './containers/MaintenanceMode';
import BaseLayout from './templates/BaseLayout';
import Header from './templates/Header';
import Footer from './templates/Footer';
import { Spinner } from './common';
import { Navigation } from './components';
import useGlobalContext, {
  GlobalContextProvider,
} from './hooks/useGlobalContext';
import { OnlineStatusProvider } from './hooks/useOnlineStatus';
import useQueryParams from './hooks/useQueryParams';
import useOrgLocation from './hooks/useOrgLocation';

if (process?.env?.SENTRY_DSN) {
  const sentryInit = {
    dsn: process?.env?.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: process?.env?.SENTRY_CAPTURE_LEVELS?.split(',') || [],
      }),
      new Sentry.Replay({
        maskAllText: !!(process?.env?.SENTRY_REPLAY_MASK_ALL_TEXT === 'true'),
        maskAllInputs: !!(
          process?.env?.SENTRY_REPLAY_MASK_ALL_INPUTS === 'true'
        ),
        blockAllMedia: !!(
          process?.env?.SENTRY_REPLAY_BLOCK_ALL_MEDIA === 'true'
        ),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(process?.env?.SENTRY_TRACE_SAMPLE_RATE) || 0,
    replaysSessionSampleRate:
      parseFloat(process?.env?.SENTRY_REPLAY_SAMPLE_SESSIONS) || 0,
    replaysOnErrorSampleRate:
      parseFloat(process?.env?.SENTRY_REPLAY_SAMPLE_ERRORS) || 0,

    beforeSend(event, hint) {
      // Check if the error message matches a pattern indicative of the third-party errors
      const message = hint?.originalException?.message;
      if (message?.includes('Failed to fetch dynamically imported module')) {
        return null; // Don't send the event to Sentry
      }
      if (message?.includes('Connection to Indexed Database server lost')) {
        return null; // Don't send the event to Sentry
      }
      if (message?.includes('Websocket close was unclean: 1006')) {
        return null; // Don't send the event to Sentry
      }
      return event; // Send all other events normally
    },
  };
  Sentry.init(sentryInit);
}

const INIT_HEADER_FOOTER_STATE = { hideHeader: false, hideFooter: false };

const App = () => {
  const isMaintenanceMode = useMaintenanceMode();
  const [mobileHeaderContent, setMobileHeaderContent] = useState(null);
  const [{ hideHeader, hideFooter }, setHideHeaderFooter] = useState(
    INIT_HEADER_FOOTER_STATE,
  );
  const [{ isAuthenticated = null, authUser = null }, setGlobalState] =
    useGlobalContext();
  const [getMe, { data, error }] = useLazyQuery(GET_USER_BY_ID, {
    context: { headers: { ['x-hasura-role']: 'user' } },
  });

  const {
    login = null,
    jwt = null,
    locationId: locId,
    embedded = false,
  } = useQueryParams();
  const authWithJwtLoadingRef = useRef(!!jwt);
  const isEmbeddedRef = useRef(embedded);
  const locationIdRef = useRef(locId);
  const [isEmbedded, setIsEmbedded] = useState(isEmbeddedRef.current);

  useOrgLocation(true); // Init org location

  const setAuthUser = ({ isAuthenticated = null, authUser = null }) => {
    setGlobalState({ isAuthenticated, authUser });
  };

  useEffect(() => {
    if (jwt) {
      (async () =>
        await signInWithToken(jwt).catch((err) => console.error(err)))();
    }
  }, [jwt]);

  useEffect(() => {
    if (embedded) {
      isEmbeddedRef.current = true;
      setIsEmbedded(true);
      setGlobalState({ isEmbedded: true });
    }
  }, [embedded]);

  useEffect(() => {
    if (locId) {
      locationIdRef.current = locId;
      setGlobalState({ selectedLocationId: locId });
    }
  }, [locId]);

  const handleSignInUser = async (vals) => {
    const { emailAddress, password } = vals;
    await signInAuthUser(emailAddress, password).catch((err) =>
      console.error(err),
    );
  };

  useEffect(() => {
    // Method auto-logs in user on demo site with base64-encoded credentials
    if (isAuthenticated !== false) return;
    if (
      !(
        process?.env?.APP_NAME === 'Ordo-DEMO' ||
        process?.env?.APP_NAME === 'Ordo-LOCAL'
      )
    )
      return;
    if (login) {
      try {
        const vals = atob(login);
        const creds = JSON.parse(vals);
        if (vals && creds) {
          const LOG_API = '/logs/report';
          handleSignInUser(creds)
            .then(() => {
              if (process?.env?.APP_NAME === 'Ordo-DEMO') {
                httpClient.post({
                  url: LOG_API,
                  body: {
                    emailAddress: creds?.emailAddress,
                    event: 'DEMO_AUTO_SIGNIN',
                  },
                });
              } else console.log('Auto-login succeeeded', creds?.emailAddress);
            })
            .catch((err) => {
              if (process?.env?.APP_NAME === 'Ordo-DEMO') {
                httpClient.post({
                  url: LOG_API,
                  body: {
                    emailAddress: creds?.emailAddress,
                    event: 'DEMO_AUTO_SIGNIN_ERROR',
                    error: err,
                  },
                });
              } else console.error(err);
            });
        } else {
          console.error('Invalid login query provided');
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [login, isAuthenticated]);

  useEffect(() => {
    onAuthChanged((au) => {
      if (au) {
        setAuthUser({ isAuthenticated: true, authUser: au });
        isAuthenticatedCache(true);
        authUserCache(au);
        Sentry.setUser({
          id: au?.uid,
          emailAddress: au?.email,
        });
        // HotJar attribution
        if (window.hj) {
          window.hj('identify', au.uid, {
            userId: au?.uid,
            emailAddress: au?.email,
            isEmbedded,
          });
        }
        if (authWithJwtLoadingRef.current) {
          authWithJwtLoadingRef.current = false;
        }
      } else if (!authWithJwtLoadingRef.current) {
        setAuthUser({ isAuthenticated: false, authUser: null });
        isAuthenticatedCache(false);
        authUserCache(null);
        client.clearStore();
      }
    });
  }, [authWithJwtLoadingRef.current]);

  useEffect(() => {
    if (isAuthenticated) {
      const { uid } = authUser;
      (async () => {
        await getMe({ variables: { id: uid } }).catch((err) =>
          console.error(err),
        );
      })();
    }
  }, [isAuthenticated, authUser]);

  const handleSetMobileHeaderContent = (content) => {
    setMobileHeaderContent(content);
  };

  const handleSetHideHeaderFooter = useCallback((hideSettings) => {
    setHideHeaderFooter(hideSettings);
  }, []);

  const renderTopNav = () => (
    <Navigation isAuthenticated={isAuthenticated} authUser={authUser} />
  );

  const renderHeader = () => (
    <Header
      isAuthenticated={isAuthenticated}
      navigation={renderTopNav()}
      mobileHeaderContent={mobileHeaderContent}
    />
  );

  const renderFooter = () => (
    <Footer isAuthenticated={isAuthenticated} navigation={null} />
  );

  const renderView = () => (
    <Routes
      isAuthenticated={isAuthenticated}
      authUser={authUser}
      onSetMobileHeaderContent={handleSetMobileHeaderContent}
      onSetHideHeaderFooter={handleSetHideHeaderFooter}
    />
  );

  const renderComponentInBaseLayout = (component) => {
    return (
      <BaseLayout
        header={!hideHeader && !isEmbedded ? renderHeader() : null}
        footer={!hideFooter && !isEmbedded ? renderFooter() : null}
        isAuthenticated={isAuthenticated}
      >
        <ErrorBoundary>{component}</ErrorBoundary>
      </BaseLayout>
    );
  };

  const renderContent = () => {
    if (isAuthenticated === null)
      return renderComponentInBaseLayout(<Spinner />);
    if (isMaintenanceMode)
      return renderComponentInBaseLayout(<MaintenanceMode />);
    return renderComponentInBaseLayout(renderView());
  };

  return <ErrorBoundary>{renderContent()}</ErrorBoundary>;
};

const globalStyles = <GlobalStyles />;

const AppWithProviders = () => (
  <ViewportProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GlobalContextProvider>
        <OnlineStatusProvider>
          <RouterProvider>
            <GlobalThemeProvider>
              {globalStyles}
              <ApolloProvider client={client}>
                <App />
              </ApolloProvider>
            </GlobalThemeProvider>
          </RouterProvider>
        </OnlineStatusProvider>
      </GlobalContextProvider>
    </LocalizationProvider>
  </ViewportProvider>
);

// TODO Service workers on iPad app may require entitlement

const removeLoadingScreen = () => {
  const loadingScreen = document.getElementById('loading-screen');
  if (loadingScreen) {
    loadingScreen.style.opacity = '0';
    loadingScreen.style.transition = 'opacity 0.5s ease';

    setTimeout(() => {
      if (loadingScreen.parentNode) {
        loadingScreen.parentNode.removeChild(loadingScreen);
      }
    }, 500);
  }
};

ReactDOM.render(
  <AppWithProviders />,
  document.getElementById('root'),
  removeLoadingScreen,
);

if (process?.env?.APP_NAME.includes('LOCAL')) {
  // Hot Module Replacement for local dev
  new EventSource('/esbuild').addEventListener('change', () =>
    location.reload(),
  );
}
