import React from 'react';
import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { COLORS } from './Consts';

export const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  ${'' /* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap'); */}
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/public/fonts/Rubik-Light.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/public/fonts/Rubik-Medium.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(/public/fonts/mui-icons.woff2) format('woff2');
  }

  :root {
    --brand: ${COLORS.brand};
    --primary: ${COLORS.primary};
    --link-color: ${COLORS.purple};
    --link-hover-color: ${COLORS.purple};

    --black: ${COLORS.black};
    --white: ${COLORS.white};
    --charcoal: ${COLORS.charcoal};
    --darkGray: ${COLORS.darkGray};
    --gray: ${COLORS.gray};
    --altGray: ${COLORS.altGray};
    --lightGray: ${COLORS.lightGray};
    --yellow: ${COLORS.yellow};
    --red: ${COLORS.red};
    --green: ${COLORS.green};
    --orange: ${COLORS.orange};
    --purple: ${COLORS.purple};

    --doc-height: 100%;
  }

  * {
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    box-sizing: border-box;
  }

  button, input, select, li, a, p, span, div, label, h1, h2, h3, h4, h5, h6, text {
    font-family: 'Rubik', sans-serif;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: var(--green);
    :hover {
      color: var(--darkGray);
      cursor: pointer;
    }
  }

  html {
    min-height: -webkit-fill-available;
  }

  body {
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    background-color: var(--white) !important;
    min-height: -webkit-fill-available;
  }
`;

export const themeOptions = {
  typography: {
    fontFamily: [
      'Rubik',
      'sans-serif',
    ].join(','),
  },
  palette: {
    type: 'light',
    primary: {
      main: COLORS.black,
    },
    secondary: {
      main: COLORS.black,
    },
    background: {
      default: COLORS.white,
      paper: COLORS.white,
    },
    info: {
      main: COLORS.black,
    },
    success: {
      main: COLORS.black,
      contrastText: COLORS.white,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: 'Rubik',
          backgroundColor: COLORS.charcoal,
          '&:hover': {
            backgroundColor: COLORS.black,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: COLORS.gray,
        },
        shrink: {
          color: COLORS.charcoal,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
        },
        contained: {
          fontWeight: 400,
          border: 'none',
          boxShadow: 'none',
          borderRadius: '5px',
          // color: COLORS.primary,
          backgroundColor: COLORS.primary,
          padding: '0.75rem 1rem',
          '&:hover': {
            color: COLORS.white,
            backgroundColor: COLORS.primary,
            boxShadow: 'none',
            opacity: 0.9,
          },
          '&.Mui-disabled': {
            color: COLORS.primary,
            backgroundColor: COLORS.gray,
            cursor: 'not-allowed',
          },
        },
        outlined: {
          fontWeight: 500,
          border: `1px solid ${COLORS.gray}`,
          color: COLORS.charcoal,
          // backgroundColor: COLORS.charcoal,
          padding: '0.75rem 1rem',
          '&:hover': {
            color: COLORS.black,
            border: `1px solid ${COLORS.black}`,
            backgroundColor: COLORS.lightGray,
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            color: COLORS.gray,
            cursor: 'not-allowed',
          },
        },
        text: {
          color: COLORS.primary,
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {},
        loadingIndicator: {
          color: COLORS.white,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            // backgroundColor: 'red',
            // display: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          '&.MuiButtonBase-root': {
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontFamily: 'Material Icons',
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 1,
          letterSpacing: 'normal',
          textTransform: 'none',
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordWrap: 'normal',
          direction: 'ltr',
          textTendering: 'optimizeLegibility',
          WebkitFontSmoothing: 'antialiased',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 500,
          fontSize: '3.5rem',
        },
        h2: {
          fontWeight: 500,
          fontSize: '3rem',
        },
        h3: {
          fontWeight: 500,
          fontSize: '2.5rem',
        },
        h4: {
          fontWeight: 500,
          fontSize: '2rem',
        },
        h5: {
          fontWeight: 500,
          fontSize: '1.5rem',
        },
        h6: {
          fontWeight: 500,
          fontSize: '1rem',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
        },
        root: {
          '&.Mui-disabled': {
            backgroundColor: COLORS.lightGray,
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          button: {
            display: 'none',
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&$disabled': {
          },
          '&$selected': {
          },
        },
        today: {
        },
      },
    },
    MuiPickersModalDialog: {
      styleOverrides: {
        dialogAction: {
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
        },
      },
    }
  },
};

let timeoutId = null;
const documentHeight = () => {
  clearTimeout(timeoutId); // avoid execution of previous timeouts
  timeoutId = setTimeout(() => {
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
  }, 200);
};
window.addEventListener('resize', documentHeight);
documentHeight();

const theme = createTheme(themeOptions);

export const GlobalThemeProvider = ({ children, ...props }) => (
  React.createElement(
    ThemeProvider,
    { ...props, theme },
    children,
  )
);
